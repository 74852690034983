import UserDataService from "@/services/UserDataService";
import Swal from "sweetalert2";
import router from '@/router';

export default {
	penggunaDaftarSubmit1(context, values) {
		context.commit('setDaftarSubmitLoadingTrue');
		UserDataService.create(values)
			.then((response) => {
				if (response.data.success == false) {
					Swal.fire({
						icon: "warning",
						title: "Oops...",
						html: response.data.message,
					});
				}
				const createFreeProductData = {
					"id": parseInt(response.data.data.id),
					"first_name": values.firstName,
					"last_name": values.lastName,
					"email": values.email,
					"telp": values.telp,
					"status": "PAID",
				}
				context.userSubmittedData = response.data;
				context.commit('setPenggunaNama', response.data.data.first_name);
				context.commit('setPenggunaID', response.data.data.id);
				context.commit('setPenggunaTelp', values.telp);
				UserDataService.createFreeProductNormalSignup2(createFreeProductData)
					.then((response2) => {
						Swal.fire({
							title: "Pendaftaran Berhasil !",
							icon: "success",
							timer: 2000,
							timerProgressBar: true,
							didOpen: () => {
								Swal.showLoading();
							},
						}).then((result) => {
							if (result.dismiss === Swal.DismissReason.timer) {
								context.commit('setCreateFreeProduct', response2.data);
								context.commit('setDaftarSubmitLoadingFalse');
								router.push({ path: "/checkout-1-149j5Q4N" });
							}
						});
					}).catch((e2) => {
						console.log(e2);
						if (e2.response) {
							Swal.fire({
								icon: "warning",
								title: "Oops...",
								text: e2.response.data.message,
							});
						}
					})
			})
			.catch((e) => {
				console.log(e);
				if (e.response) {
					Swal.fire({
						icon: "warning",
						title: "Oops...",
						text: e.response.data.message,
					});
				}
				context.commit('setDaftarSubmitLoadingFalse');
			});
	},
	penggunaDaftarSubmit2(context, values) {
		context.commit('setDaftarSubmitLoadingTrue');
		UserDataService.create(values)
			.then((response) => {
				if (response.data.success == false) {
					Swal.fire({
						icon: "warning",
						title: "Oops...",
						html: response.data.message,
					});
				}
				const createFreeProductData = {
					"id": parseInt(response.data.data.id),
					"first_name": values.firstName,
					"last_name": values.lastName,
					"email": values.email,
					"telp": values.telp,
					"status": "PAID",
				}
				context.userSubmittedData = response.data;
				context.commit('setPenggunaNama', response.data.data.first_name);
				context.commit('setPenggunaID', response.data.data.id);
				context.commit('setPenggunaTelp', values.telp);
				UserDataService.createFreeProductNormalSignup2(createFreeProductData)
					.then((response2) => {
						Swal.fire({
							title: "Pendaftaran Berhasil !",
							icon: "success",
							timer: 2000,
							timerProgressBar: true,
							didOpen: () => {
								Swal.showLoading();
							},
						}).then((result) => {
							if (result.dismiss === Swal.DismissReason.timer) {
								context.commit('setCreateFreeProduct', response2.data);
								context.commit('setDaftarSubmitLoadingFalse');
								router.push({ path: "/checkout-2-99x8G7Km" });
							}
						});
					}).catch((e2) => {
						console.log(e2);
						if (e2.response) {
							Swal.fire({
								icon: "warning",
								title: "Oops...",
								text: e2.response.data.message,
							});
						}
					})
			})
			.catch((e) => {
				console.log(e);
				if (e.response) {
					Swal.fire({
						icon: "warning",
						title: "Oops...",
						text: e.response.data.message,
					});
				}
				context.commit('setDaftarSubmitLoadingFalse');
			});
	},
	penggunaDaftarSubmit3(context, values) {
		context.commit('setDaftarSubmitLoadingTrue');
		UserDataService.create(values)
			.then((response) => {
				if (response.data.success == false) {
					Swal.fire({
						icon: "warning",
						title: "Oops...",
						html: response.data.message,
					});
				}
				const createFreeProductData = {
					"id": parseInt(response.data.data.id),
					"name": values.firstName,
					"last_name": values.lastName,
					"email": values.email,
					"telp": values.telp,
					"status": "PAID",
				}
				context.userSubmittedData = response.data;
				context.commit('setPenggunaNama', response.data.data.first_name);
				context.commit('setPenggunaID', response.data.data.id);
				context.commit('setPenggunaTelp', values.telp);
				UserDataService.createFreeProductNormalSignup2(createFreeProductData)
					.then((response2) => {
						Swal.fire({
							title: "Pendaftaran Berhasil !",
							icon: "success",
							timer: 2000,
							timerProgressBar: true,
							didOpen: () => {
								Swal.showLoading();
							},
						}).then((result) => {
							if (result.dismiss === Swal.DismissReason.timer) {
								context.commit('setCreateFreeProduct', response2.data);
								context.commit('setDaftarSubmitLoadingFalse');
								router.push({ path: "/checkout-3-49h4S3Iu" });
							}
						});
					}).catch((e2) => {
						console.log(e2);
						if (e2.response) {
							Swal.fire({
								icon: "warning",
								title: "Oops...",
								text: e2.response.data.message,
							});
						}
					})
			})
			.catch((e) => {
				console.log(e);
				if (e.response) {
					Swal.fire({
						icon: "warning",
						title: "Oops...",
						text: e.response.data.message,
					});
				}
				context.commit('setDaftarSubmitLoadingFalse');
			});
	},
	penggunaDaftarFreeSubmit(context, values) {
		context.commit('setDaftarSubmitLoadingTrue');
		UserDataService.create(values)
			.then((response) => {
				if (response.data.success == false) {
					Swal.fire({
						icon: "warning",
						title: "Oops...",
						html: response.data.message,
					});
				}
				const createFreeProductData = {
					"id": parseInt(response.data.data.id),
					"title_name": values.titleName,
					"first_name": values.firstName,
					"last_name": values.lastName,
					"email": values.email,
					"telp": values.telp,
					"status": "PAID",
					"know": values.know,
				}
				context.userSubmittedData = response.data;
				context.commit('setPenggunaNama', response.data.data.first_name);
				context.commit('setPenggunaID', response.data.data.id);
				context.commit('setPenggunaTelp', values.telp);
				UserDataService.createFreeProduct2(createFreeProductData)
					.then((response2) => {
						Swal.fire({
							title: "Pendaftaran Berhasil !",
							icon: "success",
							timer: 2000,
							timerProgressBar: true,
							didOpen: () => {
								Swal.showLoading();
							},
						}).then((result) => {
							if (result.dismiss === Swal.DismissReason.timer) {
								context.commit('setCreateFreeProduct', response2.data);
								context.commit('setDaftarSubmitLoadingFalse');
								router.push({ path: "/signup-free-success" });
							}
						});
					}).catch((e2) => {
						console.log(e2);
						if (e2.response) {
							Swal.fire({
								icon: "warning",
								title: "Oops...",
								text: e2.response.data.message,
							});
						}
					})
			})
			.catch((e) => {
				console.log(e);
				if (e.response) {
					Swal.fire({
						icon: "warning",
						title: "Oops...",
						text: e.response.data.message,
					});
				}
				context.commit('setDaftarSubmitLoadingFalse');
			});
	}
};
