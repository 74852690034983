export default {
	paymentChannels(state) {
		return state.paymentChannels;
	},
	checkoutSubmitLoading(state) {
		return state.checkoutSubmitLoading;
	},
	hargaDiskon(state) {
		return state.hargaDiskon;
	},
	paymentChannelsLoading(state) {
		return state.paymentChannelsLoading;
	},
	paymentReqResponse(state) {
		return state.paymentReqResponse;
	},
	paymentDatetime(state) {
		return state.paymentDatetime;
	},
	paymentCheck(state) {
		return state.paymentCheck;
	},
	disableResendEmailBtn(state) {
		return state.disableResendEmailBtn;
	},
	disableResendEmailBtnFree(state) {
		return state.disableResendEmailBtnFree;
	},
	signupRouterLinkData1(state) {
		return state.signupRouterLinkData1;
	},
	signupRouterLinkData2(state) {
		return state.signupRouterLinkData2;
	},
	signupRouterLinkData3(state) {
		return state.signupRouterLinkData3;
	},
	signupCategoryActiveEl(state) {
		return state.signupCategoryActiveEl;
	},
	userLoginBtnMasukLoading(state) {
		return state.userLoginBtnMasukLoading;
	},
	lpCountdown(state) {
		return state.lpCountdown;
	},
	confirmPaymentShow(state) {
		return state.confirmPaymentShow;
	},
	paymentReqResponseReferenceCompare3(state) {
		return state.paymentReqResponseReferenceCompare3;
	},
	confirmPaymentStateClick(state) {
		return state.confirmPaymentStateClick;
	},
	hargaAsli(state) {
		return state.hargaAsli;
	},
	diskon(state) {
		return state.diskon;
	},
	productName(state) {
		return state.productName;
	},
	isOfferEnd1(state) {
		return state.isOfferEnd1;
	},
	offerEndDate1(state) {
		return state.offerEndDate1;
	},
	offerEndDateLimited1(state) {
		return state.offerEndDateLimited1;
	},
	countdownTime1(state) {
		return state.countdownTime1;
	},
	isOfferEnd2(state) {
		return state.isOfferEnd2;
	},
	offerEndDate2(state) {
		return state.offerEndDate2;
	},
	offerEndDateLimited2(state) {
		return state.offerEndDateLimited2;
	},
	countdownTime2(state) {
		return state.countdownTime2;
	},
};
