import http from "../http-common";

class CheckOutDataService {
  // getAll() {
  //   return http.get("/v2/api.php?function=channels");
  // }
  getAll2() {
    return http.get("/v2/api/channels.php");
  }
	// paymentReq(data) {
	// 	return http.post("/v2/api.php?function=payment_req", data);
	// }
	paymentReq2(data) {
		return http.post("/v2/api/payment_req.php", data);
	}
	// paymentReqFromMemberPage(data) {
	// 	return http.post("/v2/api.php?function=payment_req_from_member_page", data);
	// }
	paymentReqFromMemberPage2(data) {
		return http.post("/v2/api/payment_req_from_member_page.php", data);
	}
	// sendUserIdPost(data) {
	// 	return http.post("/v2/api.php?function=payment_check", data);
	// }
	sendUserIdPost2(data) {
		return http.post("/v2/api/payment_check.php", data);
	}
	// sendUserIdEmail(data) {
	// 	return http.post("/v2/api.php?function=email_sent_paid", data);
	// }
	sendUserIdEmail2(data) {
		return http.post("/v2/api/email_sent_paid.php", data);
	}
	// sendUserIdEmailFree(data) {
	// 	return http.post("/v2/api.php?function=email_sent_free", data);
	// }
	sendUserIdEmailFree2(data) {
		return http.post("/v2/api/email_sent_free.php", data);
	}
}

export default new CheckOutDataService();