import http from "../http-common";

class UserDataService {
  create(data) {
    return http.post("/rest-api/registration.php", data);
  }
  // createFreeProduct(data) {
  //   return http.post("/v2/api.php?function=callback_payment_free", data);
  // }
  createFreeProduct2(data) {
    return http.post("/v2/api/callback_payment_free.php", data);
  }
  // createFreeProductNormalSignup(data) {
  //   return http.post("/v2/api.php?function=callback_payment_normal_signup", data);
  // }
  createFreeProductNormalSignup2(data) {
    return http.post("/v2/api/callback_payment_normal_signup.php", data);
  }
	login(data) {
		return http.post("/rest-api/api_login.php", data);
	}
}

export default new UserDataService();
