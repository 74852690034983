import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js";

export default {
  state: {
    whatsappContact:
      "https://api.whatsapp.com/send?phone=6281340184217&text=Halo%20kak%2C%20saya%20mau%20tanya-tanya%20tentang%20produk%20siaptes.com",
    userSubmittedData: {},
    passVisibility: false,
    passConfirmVisibility: false,
    changePassOldPassVisibility: false,
    changePassNewPassVisibility: false,
    changePassNewConfirmPassVisibility: false,
    penggunaChangePasswordRes: {},
    penggunaEditProfileRes: {},
		daftarSubmitLoading: false,
		titleName: "Sapaan Anda",
		titleNameError: false,
		penggunaEmail: null,
		penggunaID: null,
		penggunaNama: null,
		penggunaNamaBelakang: null,
		penggunaTelp: null,
		createFreeProduct: null,
  },
  actions,
  mutations,
  getters,
};
