import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/",
		name: "root2",
		component: () => import("../views/RootPage2.vue"),
	},
	{
		path: "/30-skill-belajar-digital-marketing-1-149j5Q4N",
		name: "30+ Skill DM 149",
		component: () => import("../views/GoogleAds1.vue"),
	},
	{
		path: "/30-skill-belajar-digital-marketing-2-99x8G7Km",
		name: "30+ Skill DM 99",
		component: () => import("../views/GoogleAds2.vue"),
	},
	{
		path: "/30-skill-belajar-digital-marketing-3-49h4S3Iu",
		name: "30+ Skill DM 49",
		component: () => import("../views/GoogleAds3.vue"),
	},
	{
		path: "/skill",
		name: "30+ Skill DM 4 Price with Ads",
		component: () => import("../views/GoogleAds4.vue"),
	},
	{
		path: "/skill-digital-marketing-8H3Kn",
		name: "30+ Skill DM 4 Price without Ads",
		component: () => import("../views/GoogleAds5.vue"),
	},
	{
		path: "/panduan-landing-page",
		name: "Panduan Landing Page",
		component: () => import("../views/PanduanLP.vue"),
	},
	{
		path: "/bonus-premium-1",
		name: "Bonus Premium 1",
		component: () => import("../views/BonusPremium1.vue"),
	},
	{
		path: "/bonus-premium-2",
		name: "Bonus Premium 2",
		component: () => import("../views/BonusPremium2.vue"),
	},
	{
		path: "/bonus-premium-3",
		name: "Bonus Premium 3",
		component: () => import("../views/BonusPremium3.vue"),
	},
	{
		path: "/bonus-premium-4",
		name: "Bonus Premium 4",
		component: () => import("../views/BonusPremium4.vue"),
	},
	{
		path: "/pricing",
		name: "Harga",
		component: () => import("../views/Pricing.vue"),
	},
	{
		path: "/contact-us",
		name: "Hubungi Kami",
		component: () => import("../views/ContactUs.vue"),
	},
	{
		path: "/about-us",
		name: "Tentang Kami",
		component: () => import("../views/AboutUs.vue"),
	},
	{
		path: "/default-landing-page",
		name: "root",
		component: () => import("../views/RootPage.vue"),
	},
	{
		path: "/spesial-promo",
		name: "Product",
		component: () => import("../views/Product.vue"),
	},
	{
		path: "/signup-1",
		name: "signup 1",
		component: () => import("../views/SignUp1.vue"),
		children: [
			{
				path: "login-149j5Q4N",
				name: "login 1",
				component: () => import("../components/signup/Login1.vue"),				
			},			
			{
				path: "register-149j5Q4N",
				name: "register 1",
				component: () => import("../components/signup/SignForm1.vue"),				
			}			
		]
	},
	{
		path: "/signup-2",
		name: "signup 2",
		component: () => import("../views/SignUp2.vue"),
		children: [
			{
				path: "login-99x8G7Km",
				name: "login 2",
				component: () => import("../components/signup/Login2.vue"),				
			},			
			{
				path: "register-99x8G7Km",
				name: "register 2",
				component: () => import("../components/signup/SignForm2.vue"),				
			}			
		]
	},
	{
		path: "/signup-3",
		name: "signup 3",
		component: () => import("../views/SignUp3.vue"),
		children: [
			{
				path: "login-49h4S3Iu",
				name: "login 3",
				component: () => import("../components/signup/Login3.vue"),				
			},			
			{
				path: "register-49h4S3Iu",
				name: "register 3",
				component: () => import("../components/signup/SignForm3.vue"),				
			}			
		]
	},
	{
		path: "/signup-free",
		name: "signup free",
		component: () => import("../views/SignUpFree.vue"),
	},
	{
		path: "/signup-free-success",
		name: "signup free success",
		component: () => import("../views/SignUpFreeSuccess.vue"),
	},
	{
		path: "/checkout-1-149j5Q4N",
		name: "checkout 1",
		component: () => import("../views/CheckOut1.vue"),
	},
	{
		path: "/checkout-2-99x8G7Km",
		name: "checkout 2",
		component: () => import("../views/CheckOut2.vue"),
	},
	{
		path: "/checkout-3-49h4S3Iu",
		name: "checkout 3",
		component: () => import("../views/CheckOut3.vue"),
	},
	{
		path: "/checkout2",
		name: "checkout from member page",
		component: () => import("../views/CheckOutFromMemberPage.vue"),
	},
	{
		path: "/invoice",
		name: "invoice",
		component: () => import("../views/Invoice.vue"),
	},
	{
		path: "/bonus-hosting",
		name: "bonus hosting",
		component: () => import("../views/BonusRoot.vue"),
	},
	{
		path: "/paket-personal-bisnis-1-bulan",
		name: "bonus personal bisnis 1 bulan",
		component: () => import("../views/Bonus1.vue"),
	},
	{
		path: "/paket-personal-1-tahun",
		name: "bonus personal 1 tahun",
		component: () => import("../views/Bonus2.vue"),
	},
	{
		path: "/paket-bisnis-1-tahun",
		name: "bonus bisnis 1 tahun",
		component: () => import("../views/Bonus3.vue"),
	},
	{
		path: "/paket-personal-3-tahun",
		name: "bonus personal 3 tahun",
		component: () => import("../views/Bonus4.vue"),
	},
	{
		path: "/paket-bisnis-3-tahun",
		name: "bonus bisnis 3 tahun",
		component: () => import("../views/Bonus5.vue"),
	},
	{
		name: "Page Not Found",
		path: "/:notFound(.*)",
		component: () => import("../views/NotFound")
	}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
	scrollBehavior (to) {
		if (to.hash) {
			return {
				el: to.hash,
				behavior: 'smooth',
			}
		}
	}
});

export default router;
