<template>
  <router-view />
</template>

<style>
	#app {
		font-family: 'Quicksand', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* color: #2c3e50; */
		/* background-color: #5890FF; */
	}
	</style>