export default {
	setCheckoutSubmitLoadingTrue(state) {
		state.checkoutSubmitLoading = true;
	},
	setCheckoutSubmitLoadingFalse(state) {
		state.checkoutSubmitLoading = false;
	},
	setPaymentChannels(state, data) {
		state.paymentChannels = data;
	},
	setHargaDiskon(state, data) {
		state.hargaDiskon = data;
	},
	setPaymentReqResponse(state, data) {
		state.paymentReqResponse = data;
	},
	setPaymentChannelsLoadingTrue(state) {
		state.paymentChannelsLoading = true;
	},
	setPaymentChannelsLoadingFalse(state) {
		state.paymentChannelsLoading = false;
	},
	setPaymentDatetimeNew(state, data) {
		state.paymentDatetime = data;
	},
	setPaymentCheck(state, data) {
		state.paymentCheck = data;
	},
	setPaymentCheckReset(state) {
		state.paymentCheck = {
			"success": null,
			"data": {
				"payment_desc_1": null,
				"payment_desc_2": null,
				"payment_desc_3": null,
				"payment_desc_4": null,
				"payment_desc_5": null,
			}
		}
	},
	setDisableResendEmailBtnTrue(state) {
		state.disableResendEmailBtn = true;
	},
	setDisableResendEmailBtnFalse(state) {
		state.disableResendEmailBtn = false;
	},
	setDisableResendEmailBtnFreeTrue(state) {
		state.disableResendEmailBtnFree = true;
	},
	setDisableResendEmailBtnFreeFalse(state) {
		state.disableResendEmailBtnFree = false;
	},
	setSendUserIdInterval(state, data) {
		if (state.payCheckTimerInterval) {
			clearInterval(state.payCheckTimerInterval);
		}
		state.payCheckTimerInterval = data;
	},
	setSendUserIdIntervalClear(state) {
		if (state.payCheckTimerInterval) {
			clearInterval(state.payCheckTimerInterval);
		}
	},
	setSignupCategoryActiveEl(state, data) {
		state.signupCategoryActiveEl = data;
	},
	setUserLoginBtnMasukLoadingTrue(state) {
		state.userLoginBtnMasukLoading = true;
	},
	setUserLoginBtnMasukLoadingFalse(state) {
		state.userLoginBtnMasukLoading = false;
	},
	setConfirmPaymentShowTrue(state) {
		state.confirmPaymentShow = true;
	},
	setConfirmPaymentShowFalse(state) {
		state.confirmPaymentShow = false;
	},
	setPaymentReqResponseReferenceCompare3(state, data) {
		state.paymentReqResponseReferenceCompare3 = data;
	},
	setConfirmPaymentStateClick(state, data) {
		state.confirmPaymentStateClick = state.confirmPaymentStateClick + data;
	},
	setConfirmPaymentStateReset(state, data) {
		state.confirmPaymentStateClick = data;
	},
	setProductName(state, data) {
		state.productName = data;
	},
	setHargaAsli(state, data) {
		state.hargaAsli = data;
	},
	setDiskon(state, data) {
		state.diskon = data;
	},
	setIsOfferEnd1(state, data) {
		state.isOfferEnd1 = data;
	},
	setCountdownTime1(state, data) {
		state.countdownTime1 = data;
	},
	setIsOfferEnd2(state, data) {
		state.isOfferEnd2 = data;
	},
	setCountdownTime2(state, data) {
		state.countdownTime2 = data;
	}
};