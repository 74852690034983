import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js";

export default {
	state: {
		// url : /skill
		offerEndDate1: "April 30, 2023 23:59:59",
		offerEndDateLimited1: "30 April 2023",
		isOfferEnd1: false,
		countdownTime1: null,
		// url : /skill-digital-marketing-8H3Kn
		offerEndDate2: "April 28, 2023 23:59:59",
		offerEndDateLimited2: "28 April 2023",
		isOfferEnd2: false,
		countdownTime2: null,
		lpCountdown: 1 * 1 * 60 * 60 * 1000,
		paymentChannels: {
			success: null,
			message: "",
			data: [
				{
					"group": "",
					"code": "",
					"name": "",
					"type": "",
					"fee_merchant": {
						"flat": null,
						"percent": null
					},
					"fee_customer": {
						"flat": null,
						"percent": null
					},
					"total_fee": {
						"flat": null,
						"percent": ""
					},
					"icon_url": "",
					"active": null
				},
			]
		},
		checkoutSubmitLoading: false,
		hargaDiskon: '-',
		hargaAsli: null,
		diskon: null,
		productName: null,
		paymentReqResponse: {
			success: null,
			message: null,
			data: {
				"amount": null,
				"amount_received": null,
				"customer_email": null,
				"customer_name": null,
				"customer_phone": null,
				"expired_time": null,
				"fee_customer": null,
				"fee_merchant": null,
				"instructions": [
					{
						"title": null,
						"steps": []
					}
				],
				"order_items": [
					{
						"image_url": null,
						"name": null,
						"price": null,
						"product_url": null,
						"quantity": null,
						"sku": null,
						"subtotal": null
					}
				],
				"pay_code": null,
				"payment_method": null,
				"payment_name": null,
				"payment_selection_type": null,
				"reference": null,
				"status": null,
				"total_fee": null,
				"qr_string": null,
				"qr_url": null,
			}
		},
		paymentReqResponseReferenceCompare3: null,
		paymentChannelsLoading: true,
		paymentDatetime: null,
		paymentCheck: {
			"success": null,
			"data": {
				"payment_desc_1": null,
				"payment_desc_2": null,
				"payment_desc_3": null,
				"payment_desc_4": null,
				"payment_desc_5": null,
			}
		},
		disableResendEmailBtn: false,
		disableResendEmailBtnFree: false,
		confirmPaymentShow: true,
		confirmPaymentStateClick: 0,
		payCheckTimer: '',
		payCheckTimerInterval: null,
		payCheckPollTimer: 5000, // 5 detik
		passVisibility: false,
		signupCategoryActiveEl: 0,
		userLoginBtnMasukLoading: false,
		signupRouterLinkData1: [
			{
				id: "1",
				text: "Login",
				routerLinkTo: "/signup-1/login-149j5Q4N",
			},
			{
				id: "2",
				text: "Register",
				routerLinkTo: "/signup-1/register-149j5Q4N",
			},
		],			
		signupRouterLinkData2: [
			{
				id: "1",
				text: "Login",
				routerLinkTo: "/signup-2/login-99x8G7Km",
			},
			{
				id: "2",
				text: "Register",
				routerLinkTo: "/signup-2/register-99x8G7Km",
			},
		],			
		signupRouterLinkData3: [
			{
				id: "1",
				text: "Login",
				routerLinkTo: "/signup-3/login-49h4S3Iu",
			},
			{
				id: "2",
				text: "Register",
				routerLinkTo: "/signup-3/register-49h4S3Iu",
			},
		],			
	},
	actions,
	mutations,
	getters,
};
