import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js";

export default {
  state: {
    key: "DEV-JHQYCXKv7AYkUNtipTBaQxYYw72rSVYXvBl0Ud4j",
  },
  actions,
  mutations,
  getters,
};
