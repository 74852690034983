export default {
	setDaftarSubmitLoadingTrue(state) {
		state.daftarSubmitLoading = true;
	},
	setDaftarSubmitLoadingFalse(state) {
		state.daftarSubmitLoading = false;
	},
	setPenggunaEmail(state, data) {
		state.penggunaEmail = data;
	},
	setPenggunaEmailClear(state) {
		state.penggunaEmail = null;
	},
	setPenggunaID(state, data) {
		state.penggunaID = data;
	},
	setPenggunaNama(state, data) {
		state.penggunaNama = data;
	},
	setPenggunaNamaBelakang(state, data) {
		state.penggunaNamaBelakang = data;
	},
	setCreateFreeProduct(state, data) {
		state.createFreeProduct = data;
	},
	setPenggunaTelp(state, data) {
		state.penggunaTelp = data;
	},
	setTitleName(state, data) {
		state.titleName = data;
	},
	setTitleNameErrorTrue(state) {
		state.titleNameError = true;
	},
	setTitleNameErrorFalse(state) {
		state.titleNameError = false;
	}
};
