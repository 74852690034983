import CheckOutDataService from "@/services/CheckOutDataService";
import UserDataService from "@/services/UserDataService";
import Swal from "sweetalert2";
import router from '@/router';
import moment from "moment-timezone";
import "moment/locale/id";

export default {
	channelPembayaranGetContent(context) {
		CheckOutDataService.getAll2()
			.then((response) => {
				// console.log(response.data);
				context.commit('setPaymentChannels', response.data);
				context.commit('setPaymentChannelsLoadingFalse');
			})
			.catch((e) => {
				console.log(e);
				context.commit('setPaymentChannelsLoadingFalse');
			});
	},
	infoPembayaran() {
		Swal.fire({
			html: '<h4><strong>Pilihan Metode Pembayaran</strong></h4><br /> <img alt="Metode Pembayaran" src="https://files5.s3.ap-southeast-3.amazonaws.com/images/assets/payment-2.jpg" style="max-width: 100%; height: auto;"> <br /><br /> <strong>Virtual Account / Toko Retail / E-Wallet</strong> <br /> Dicek dan konfirmasi otomatis kurang dari 5 menit.',
			confirmButtonText:
			'Saya mengerti',
		});
	},
	paymentMethodSubmit(context, values) {
		context.commit('setCheckoutSubmitLoadingTrue');
		CheckOutDataService.paymentReq2(values)
			.then((response) => {
				// console.log(response.data);
				// console.log(moment().tz("Asia/Jakarta").format("D MMM YYYY H:mm:ss z"));
				const paymentDatetime = moment().tz("Asia/Jakarta").format("D MMM YYYY H:mm:ss z");
				context.commit('setPaymentDatetimeNew', paymentDatetime);
				context.commit('setPaymentReqResponse', response.data);
				context.commit('setCheckoutSubmitLoadingFalse');
				router.push({ path: "/invoice" });
				// console.log(context.rootState.checkOut.paymentReqResponse);
			})
			.catch((e) => {
				console.log(e);
				context.commit('setCheckoutSubmitLoadingFalse');
			});
	},
	paymentMethodSubmit2(context, values) {
		context.commit('setCheckoutSubmitLoadingTrue');
		CheckOutDataService.paymentReqFromMemberPage2(values)
			.then((response) => {
				// console.log(response.data);
				// console.log(moment().tz("Asia/Jakarta").format("D MMM YYYY H:mm:ss z"));
				const paymentDatetime = moment().tz("Asia/Jakarta").format("D MMM YYYY H:mm:ss z");
				// console.log(paymentDatetime);
				context.commit('setPaymentDatetimeNew', paymentDatetime);
				context.commit('setPaymentReqResponse', response.data);
				context.commit('setCheckoutSubmitLoadingFalse');
				router.push({ path: "/invoice" });
				// console.log(context.rootState.checkOut.paymentReqResponse);
			})
			.catch((e) => {
				console.log(e);
				context.commit('setCheckoutSubmitLoadingFalse');
			});
	},
	sendUserId(context) {
		const userId = {
			"id": context.rootState.penggunaDaftar.penggunaID
		}
		CheckOutDataService.sendUserIdPost2(userId)
			.then((response) => {
				// console.log(response.data);
				// const dataX = {
				// 	data: {
				// 		status: true
				// 	}
				// };
				// context.commit('setPaymentCheck', dataX);
				context.commit('setPaymentCheck', response.data);
				if (response.data.data.status === true) {
					context.commit('setSendUserIdIntervalClear');
					// clearInterval(context.rootState.checkOut.payCheckTimer);
					// clearInterval(context.rootState.checkOut.payCheckTimer);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	},
	sendUserIdInterval(context) {
		const interval = setInterval(() => { context.dispatch('sendUserId'); }, context.state.payCheckPollTimer);
		context.commit('setSendUserIdInterval', interval);
	},
	emailResend(context) {
		const varUserId = {
			"id": context.rootState.penggunaDaftar.penggunaID
		}
		CheckOutDataService.sendUserIdEmail2(varUserId)
			.then((response) => {
				console.log(response);
				// context.commit('setPaymentChannels', response.data);
				// context.commit('setPaymentChannelsLoadingFalse');
			})
			.catch((e) => {
				console.log(e);
			});
	},
	emailResendFree(context) {
		const varUserId = {
			"id": context.rootState.penggunaDaftar.penggunaID
		}
		CheckOutDataService.sendUserIdEmailFree2(varUserId)
			.then((response) => {
				console.log(response);
				// context.commit('setPaymentChannels', response.data);
				// context.commit('setPaymentChannelsLoadingFalse');
			})
			.catch((e) => {
				console.log(e);
			});
	},
	userLoginSubmit1(context, values) {
		// console.log(values);
		context.commit("setUserLoginBtnMasukLoadingTrue");
		UserDataService.login(values)
		.then((response) => {
			// console.log(response.data);
			if (response.data.success == false) {
				Swal.fire({
					icon: "warning",
					title: "Oops...",
					html: response.data.message,
				});
				context.commit("setUserLoginBtnMasukLoadingFalse");
			}
			if (response.data.success == true) {
				Swal.fire({
					title: "Login Berhasil !",
					icon: "success",
					timer: 2000,
					timerProgressBar: true,
					didOpen: () => {
						Swal.showLoading();
					},
				}).then((result) => {
					if (result.dismiss === Swal.DismissReason.timer) {
						context.commit("setPenggunaNama", response.data.data.first_name);
						context.commit("setPenggunaID", response.data.data.id);
						context.commit("setPenggunaEmail", response.data.data.email);
						context.commit("setPenggunaNamaBelakang", response.data.data.last_name);
						context.commit("setUserLoginBtnMasukLoadingFalse");
						router.push({ path: "/checkout-1-149j5Q4N" });
					}
				});
			}
		})
		.catch((e) => {
			console.log(e);
			context.commit("setUserLoginBtnMasukLoadingFalse");
			// console.log(e.message);
			if (e.message === 'Network Error') {
				Swal.fire({
					icon: "warning",
					title: "Gagal...",
					text: "Pastikan Jaringan Internet Anda Stabil",
				});
			} else {
				Swal.fire({
					icon: "warning",
					title: "Gagal...",
					text: e.message,
				});				
			}
		});
	},
	userLoginSubmit2(context, values) {
		// console.log(values);
		context.commit("setUserLoginBtnMasukLoadingTrue");
		UserDataService.login(values)
		.then((response) => {
			// console.log(response.data);
			if (response.data.success == false) {
				Swal.fire({
					icon: "warning",
					title: "Oops...",
					html: response.data.message,
				});
				context.commit("setUserLoginBtnMasukLoadingFalse");
			}
			if (response.data.success == true) {
				Swal.fire({
					title: "Login Berhasil !",
					icon: "success",
					timer: 2000,
					timerProgressBar: true,
					didOpen: () => {
						Swal.showLoading();
					},
				}).then((result) => {
					if (result.dismiss === Swal.DismissReason.timer) {
						context.commit("setPenggunaNama", response.data.data.first_name);
						context.commit("setPenggunaID", response.data.data.id);
						context.commit("setPenggunaEmail", response.data.data.email);
						context.commit("setPenggunaNamaBelakang", response.data.data.last_name);
						context.commit("setUserLoginBtnMasukLoadingFalse");
						router.push({ path: "/checkout-2-99x8G7Km" });
					}
				});
			}
		})
		.catch((e) => {
			console.log(e);
			context.commit("setUserLoginBtnMasukLoadingFalse");
			// console.log(e.message);
			if (e.message === 'Network Error') {
				Swal.fire({
					icon: "warning",
					title: "Gagal...",
					text: "Pastikan Jaringan Internet Anda Stabil",
				});
			} else {
				Swal.fire({
					icon: "warning",
					title: "Gagal...",
					text: e.message,
				});				
			}
		});
	},
	userLoginSubmit3(context, values) {
		// console.log(values);
		context.commit("setUserLoginBtnMasukLoadingTrue");
		UserDataService.login(values)
		.then((response) => {
			// console.log(response.data);
			if (response.data.success == false) {
				Swal.fire({
					icon: "warning",
					title: "Oops...",
					html: response.data.message,
				});
				context.commit("setUserLoginBtnMasukLoadingFalse");
			}
			if (response.data.success == true) {
				Swal.fire({
					title: "Login Berhasil !",
					icon: "success",
					timer: 2000,
					timerProgressBar: true,
					didOpen: () => {
						Swal.showLoading();
					},
				}).then((result) => {
					if (result.dismiss === Swal.DismissReason.timer) {
						context.commit("setPenggunaNama", response.data.data.first_name);
						context.commit("setPenggunaID", response.data.data.id);
						context.commit("setPenggunaEmail", response.data.data.email);
						context.commit("setPenggunaNamaBelakang", response.data.data.last_name);
						context.commit("setUserLoginBtnMasukLoadingFalse");
						router.push({ path: "/checkout-3-49h4S3Iu" });
					}
				});
			}
		})
		.catch((e) => {
			console.log(e);
			context.commit("setUserLoginBtnMasukLoadingFalse");
			// console.log(e.message);
			if (e.message === 'Network Error') {
				Swal.fire({
					icon: "warning",
					title: "Gagal...",
					text: "Pastikan Jaringan Internet Anda Stabil",
				});
			} else {
				Swal.fire({
					icon: "warning",
					title: "Gagal...",
					text: e.message,
				});				
			}
		});
	},
};
