import { createStore } from "vuex";

import createPersistedState from "vuex-persistedstate";

import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

// import addQuestion from './modules/admin/TambahSoalPremiumTryout/index';
// import addQuestionFreeTryout from './modules/admin/TambahSoalFreeTryout/index';
// import category from './modules/latihanSKD/kategori/index';
// import practice from './modules/latihanSKD/latihan/index';
// import practiceResults from './modules/latihanSKD/hasilLatihan/index';
// import stopwatchQuestion from './modules/timer/StopwatchQuestion/index';
// import countdownPractice from './modules/timer/countdownPractice/index';
// import countdownTryoutPremium from './modules/timer/countdownTryoutPremium/index';
// import countdownTryoutTPA from './modules/timer/countdownTryoutTPA/index';
// import countdownTryoutTBI from './modules/timer/countdownTryoutTBI/index';
// import countdownTryoutFree from './modules/timer/countdownTryoutFree/index';
// import userLogin from './modules/auth/userLogin/index';
import penggunaDaftar from "./modules/auth/PenggunaDaftar/index";
import checkOut from "./modules/CheckOut/index";
import apiKey from "./modules/auth/ApiKeyToken/index";
// import token from './modules/auth/token/index';
// import toSkdPremium from './modules/tryoutSKD/tryoutPremium/index';
// import toTPA from './modules/tryoutTPA/index';
// import toTBI from './modules/tryoutTBI/index';
// import toSkdGratis from './modules/tryoutSKD/tryoutGratis/index';
// import toSkdPremiumHasil from './modules/tryoutSKD/tryoutPremium/hasilTryout/index';
// import toTPA_Hasil from './modules/tryoutTPA/hasilTryout/index';
// import toTBI_Hasil from './modules/tryoutTBI/hasilTryout/index';
// import toSkdGratisHasil from './modules/tryoutSKD/tryoutGratis/hasilTryout/index';
// import materiSKD from './modules/materiSKD/index';
// import referral from './modules/referral/index';
// import subscribePacket from './modules/admin/SubscribePacket/index';

export default createStore({
  modules: {
    // addQuestion,
    // addQuestionFreeTryout,
    // category,
    // practice,
    // practiceResults,
    // stopwatchQuestion,
    // countdownPractice,
    // countdownTryoutPremium,
    // countdownTryoutTPA,
    // countdownTryoutTBI,
    // countdownTryoutFree,
    // userLogin,
    penggunaDaftar,
		checkOut,
		apiKey,
    // token,
    // toSkdPremium,
    // toTPA,
    // toTBI,
    // toSkdGratis,
    // toSkdPremiumHasil,
    // toTPA_Hasil,
    // toTBI_Hasil,
    // toSkdGratisHasil,
    // materiSKD,
    // referral,
    // subscribePacket,
  },
  plugins: [
    createPersistedState({
      paths: [
				'penggunaDaftar.penggunaEmail',
				'penggunaDaftar.penggunaID',
				'penggunaDaftar.penggunaNama',
				'penggunaDaftar.penggunaTelp',
				'penggunaDaftar.penggunaNamaBelakang',
				'checkOut.paymentReqResponse',
				'checkOut.paymentDatetime',
				'checkOut.paymentCheck',
				'checkOut.lpCountdown',
				'checkOut.confirmPaymentShow',
				'checkOut.confirmPaymentStateClick',
				'checkOut.paymentReqResponseReferenceCompare3',
				'checkOut.productName',
				'checkOut.hargaAsli',
				'checkOut.diskon',
        // 'category.practiceCategoryActiveEl',
        // 'category.practiceSubcategory_id',
        // 'category.practiceCategory_short',
        // 'category.practiceSubcategory',
        // 'category.practice_id',
        // 'practice.practice',
        // 'practice.practiceActiveButton',
        // 'practice.practicePageNumber',
        // 'practiceResults.practiceResultDetailActive_el',
        // 'token.session_key',
        // 'userLogin.user',
        // 'materiSKD.textMaterialCategoryActiveEl',
        // 'toSkdPremium.toSkdPre_id',
        // 'toSkdPremium.toSkdPreActiveButton',
        // 'toSkdPremium.toSkdPrePageNumber',
        // 'toSkdPremium.toSkdPreTryoutAnswers',
        // 'toSkdPremiumHasil.toSkdPreResultDetailActive_el',
        // 'toSkdPremiumHasil.toSkdPreTryout',
        // 'toSkdPremiumHasil.toSkdPreTryoutRes',
        // 'toSkdGratis.toSkdFree_id',
        // 'toSkdGratis.toSkdFreeActiveButton',
        // 'toSkdGratis.toSkdFreePageNumber',
        // 'toSkdGratis.toSkdFreeTryoutAnswers',
        // 'toSkdGratisHasil.toSkdFreeResultDetailActive_el',
        // 'toSkdGratisHasil.toSkdFreeTryout',
        // 'toSkdGratisHasil.toSkdFreeTryoutRes',
        // 'toTPA.toTPA_id',
        // 'toTPA.toTPA_ActiveButton',
        // 'toTPA.toTPA_PageNumber',
        // 'toTPA.toTPA_TryoutAnswers',
        // 'toTPA_Hasil.toTPA_ResultDetailActive_el',
        // 'toTPA_Hasil.toTPA_Tryout',
        // 'toTPA_Hasil.toTPA_TryoutRes',
        // 'toTBI.toTBI_id',
        // 'toTBI.toTBI_ActiveButton',
        // 'toTBI.toTBI_PageNumber',
        // 'toTBI.toTBI_TryoutAnswers',
        // 'toTBI_Hasil.toTBI_ResultDetailActive_el',
        // 'toTBI_Hasil.toTBI_Tryout',
        // 'toTBI_Hasil.toTBI_TryoutRes',
        // 'referral.referralCategoryActiveEl',
        // 'subscribePacket.subscribePacketActiveEl'
      ],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      },
    }),
  ],
});
