export default {
	daftarSubmitLoading(state) {
		return state.daftarSubmitLoading;
	},
	penggunaEmail(state) {
		return state.penggunaEmail;
	},
	penggunaID(state) {
		return state.penggunaID;
	},
	penggunaNama(state) {
		return state.penggunaNama;
	},
	penggunaNamaBelakang(state) {
		return state.penggunaNamaBelakang;
	},
	penggunaTelp(state) {
		return state.penggunaTelp;
	},
	titleName(state) {
		return state.titleName;
	},
	titleNameError(state) {
		return state.titleNameError;
	}
};
