import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap'
import "./assets/css/styles.css";
import "bootstrap/dist/js/bootstrap.min";
import 'animate.css';
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
// import { faPlay, faList, faSquareCheck, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueCountdown from '@chenfengyuan/vue-countdown';


import {
	faPlay,
	faList,
	faSquareCheck,
	faSquareXmark,
	faChevronUp,
	faUserSecret,
	faHouseUser,
	faHouse,
	faTrash,
	faEdit,
	faBars,
	faSearch,
	faCheck,
	faFlag,
	faCircleInfo,
	faArrowLeft,
	faArrowRight,
	faXmark,
	faLock,
	faBullhorn,
	faStopwatch,
	faPhone,
	faUser,
	faEnvelope,
	faUnlock,
	faKey,
	faCalendarDays,
	faEye,
	faEyeSlash,
	faChartPie,
	faSquarePollVertical,
	faCircleCheck,
	faChevronDown,
	faAlignLeft,
	faWallet,
	faDatabase,
	faRepeat,
	faCartShopping,
	faBook,
	faFileSignature,
	faUserPlus,
	faComments,
	faMoneyBillWave,
	faRightToBracket,
	faPaperPlane,
	faPen,
	faFileLines,
	faCircleRight,
	faCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

library.add(
	faUserSecret,
	faHouseUser,
	faHouse,
	faTrash,
	faEdit,
	faUser,
	faBars,
	faSearch,
	faCheck,
	faFlag,
	faCircleInfo,
	faArrowLeft,
	faArrowRight,
	faXmark,
	faLock,
	faBullhorn,
	faClock,
	faStopwatch,
	faPhone,
	faEnvelope,
	faUnlock,
	faKey,
	faCalendarDays,
	faEye,
	faEyeSlash,
	faChartPie,
	faSquarePollVertical,
	faCircleCheck,
	faChevronDown,
	faAlignLeft,
	faWhatsapp,
	faWallet,
	faDatabase,
	faRepeat,
	faCartShopping,
	faBook,
	faFileSignature,
	faUserPlus,
	faComments,
	faMoneyBillWave,
	faRightToBracket,
	faPaperPlane,
	faPen,
	faFileLines,
	faPlay,
	faList,
	faSquareCheck,
	faSquareXmark,
	faChevronUp,
	faCircleRight,
	faCircleLeft,
);

createApp(App)
	.use(router)
	.use(store)
	.use(VueSweetalert2)
	.use(Toast)
	.component("font-awesome-icon", FontAwesomeIcon)
	.component(VueCountdown.name, VueCountdown)
	.mount('#app')
